import { Status, useConductricsSelection } from '@dx-ui/framework-conductrics';

import { useMediaQuery } from 'usehooks-ts';
import { useConductrics } from './useConductrics';

export function useMCLPSearchSuppress() {
  useConductrics();
  const { isLoaded, selection } = useConductricsSelection('a-npGDKHj7WB', Status.OK);
  const isMobile = useMediaQuery('(max-width: 649px)');
  return { suppressHalfSearchWidgetForMobile: isLoaded && selection?.choice === 'B' && isMobile };
}
