import { useId } from 'react';
import { get, useFormContext } from 'react-hook-form';
import { FormErrorIcon, FormLabel } from '@dx-ui/osc-form';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';

import type { FormInputBase } from '@dx-ui/osc-form';
import type { FieldError } from 'react-hook-form';
import type { InputHTMLAttributes } from 'react';

type FormInputPasswordProps<T> = {
  passwordCriteriaClassName?: string;
} & Omit<T, 'name'>;

export const FormInputPassword: React.FC<
  React.PropsWithChildren<
    FormInputBase<FormInputPasswordProps<InputHTMLAttributes<HTMLInputElement>>>
  >
> = ({
  label,
  name,
  required,
  optional,
  registerOptions,
  labelClassName,
  className,
  containerClassName,
  passwordCriteriaClassName,
  ...rest
}) => {
  const id = useId();
  const helperId = `input-helper-${id}`;
  const [t] = useTranslation('osc-join-form-form');
  const {
    register,
    formState: { errors, touchedFields },
  } = useFormContext();

  const fieldError: FieldError = get(errors, name);
  const touchedField = get(touchedFields, name);
  const hasError = !!fieldError;

  const passRequirements = [
    { type: 'mustBe', message: t('password.requirements.mustBe') },
    { type: 'containUpper', message: t('password.requirements.containUpper') },
    { type: 'containLower', message: t('password.requirements.containLower') },
    { type: 'containOneNumber', message: t('password.requirements.containOneNumber') },
  ];

  return (
    <div className={cx({ [containerClassName as string]: !!containerClassName })}>
      <FormLabel
        label={label}
        required={required}
        optional={optional}
        hasError={hasError}
        className={cx('self-start', {
          [labelClassName as string]: !!labelClassName,
        })}
      >
        <input
          {...rest}
          className={cx('form-input', {
            [className as string]: !!className,
            'form-error': hasError,
          })}
          aria-invalid={hasError}
          aria-describedby={helperId}
          {...register(name, {
            validate: {
              required: (value: string) =>
                /^(?=.*[A-Z])(?=.*[a-z])((?=.*[0-9])|(?=.*[#?!@$%^&*-])).{8,32}$/.test(value) ||
                t('password.error'),
              mustBe: (value: string) => /.{8,32}/.test(value) || t('password.requirements.mustBe'),
              containUpper: (value: string) =>
                /(?=.*[A-Z])/.test(value) || t('password.requirements.containUpper'),
              containLower: (value: string) =>
                /(?=.*[a-z])/.test(value) || t('password.requirements.containLower'),
              containOneNumber: (value: string) =>
                /(?=.*[0-9])|(?=.*[#?!@$%^&*-])/.test(value) ||
                t('password.requirements.containOneNumber'),
            },
            ...registerOptions,
          })}
        />
      </FormLabel>
      <ul
        id={helperId}
        className={cx('mt-2 grid gap-2 text-sm md:grid-cols-2', {
          [passwordCriteriaClassName as string]: !!passwordCriteriaClassName,
        })}
      >
        {passRequirements.map(({ message, type }) => {
          const hasRequirementError = fieldError?.types?.[type];
          return (
            <li
              key={`passRequirement-${type}`}
              className={cx('flex items-center space-x-2 rtl:space-x-reverse', {
                'text-danger': hasRequirementError,
                'text-success': !hasRequirementError && touchedField,
              })}
            >
              {hasRequirementError && <FormErrorIcon className="mt-0.5 shrink-0" />}
              {!hasRequirementError && !touchedField && (
                <svg
                  role="img"
                  aria-hidden
                  viewBox="-2 0 8 8"
                  height="16"
                  width="16"
                  className="mt-0.5 shrink-0"
                >
                  <circle cx="4" cy="4" r="1" fill="#1D1D1D" />
                </svg>
              )}
              {!hasRequirementError && touchedField && (
                <svg
                  role="img"
                  aria-hidden
                  viewBox="0 -1 8 7"
                  width="16"
                  height="16"
                  className="mt-0.5 shrink-0"
                >
                  <path
                    d="M1 3l2 2 4-5"
                    stroke="#1A9448"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <span>{message}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
