import type { CpmDataBundle, KeyedMappingDefinition } from '@dx-ui/cpm-sdk';
import type { AuthClient } from '@dx-ui/framework-auth-provider';
import { getOneLinkProps, serverSideOneLinkConfig } from '@dx-ui/framework-i18n';
import { getServerSideSafeLanguage, makeServerQueryClient } from '@dx-ui/framework-react-query';
import { type QueryClient } from '@tanstack/react-query';
import type { Request as ExpressRequest } from 'express';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { REACT_QUERY_CONFIG } from '../../config/react-query';
import nextI18nextConfig from '../../next-i18next.config';
import type {
  BrandNonMemberPointsPageQuery,
  BrandPointsExplorerPageQuery,
  BrandQuery,
  BrandRewardProgramQuery,
  Brand_CmsImageHeadlinerQuery,
  Brand_CountriesQuery,
  Brand_HiltonForBusinessQuery,
  Brand_NonMemberQuery,
  CmsBrandMemberDataQuery,
  CmsPolicyQuery,
  HonorsTierBenefitsQuery,
  ProgramAccountDashboardSummaryQuery,
  SmbMarketingBannerOffersQuery,
} from '../../types/generated/types';
import { auth } from '../auth';
import { getSupportedLanguages } from '../featureToggles';
import { getDehydratedQueryState } from '../getDehydratedState';
import { getSpeedBumpFeatureConfig } from '@dx-ui/osc-speedbump';
import type { FeatureTogglesQueryData } from '@dx-ui/utilities-feature-toggles-configs';

export const QUERY_LANGUAGE_KEY = ['queryLanguage'];

type Metrics = {
  trackingData: {
    pageView: string;
    pageData: {
      pageDetail?: string[];
      pageName?: string;
      pageType: string;
      subSection?: string;
      subSubSection?: string;
      pageCategory?: string;
      primaryCategory?: string;
      isCmsPage?: boolean;
      expType?: string;
    };
  };
};

type PageProps = {
  bannerOffers?: SmbMarketingBannerOffersQuery['bannerOffers'];
  countriesData?: Brand_CountriesQuery;
  cmsData?: Brand_HiltonForBusinessQuery;
  cmsImageHeadlinerData?: Brand_CmsImageHeadlinerQuery;
  cmsPolicyData?: CmsPolicyQuery;
  cpmData?: CpmDataBundle<KeyedMappingDefinition>;
  declinedPageEnabled?: boolean;
  deleteAdminOwnerFlow?: boolean;
  env?: unknown;
  featureToggles?: FeatureTogglesQueryData['featureToggles'];
  forwardUrl?: string;
  goHiltonContent?: BrandQuery;
  hasCustomCanonicalUrl?: boolean;
  invitationKey?: string | string[] | null;
  isAmexBrandingRefreshed?: boolean;
  isH4BMultiBusinessEnabled?: boolean;
  isSMBMember?: boolean;
  layout?: Record<string, string | null | boolean>;
  memberBenefitsData?: HonorsTierBenefitsQuery;
  memberData?: CmsBrandMemberDataQuery;
  brandRewardProgramData?: BrandRewardProgramQuery;
  metrics?: Metrics;
  nonMemberData?: Brand_NonMemberQuery | BrandNonMemberPointsPageQuery;
  pointsExplorerCmsData?: BrandPointsExplorerPageQuery;
  programAccountId?: number;
  programAccountName?: string;
  remoteIp?: string;
  shouldShowPointsDistroMemberManagementPage?: boolean;
  showKPIDetails?: boolean;
  showPointsDistroMemberManagementLink?: boolean;
  showUpdatedDisclaimerAndAccountSummary?: boolean;
  showFAQLink?: boolean;
  summaryData?: ProgramAccountDashboardSummaryQuery;
};

interface CoreServerSideProps {
  authClient?: AuthClient;
  context: GetServerSidePropsContext;
  dehydratedOriginalOpNames?: string[];
  isOneLinkPage?: boolean;
  namespaces: string[];
  pageProps: PageProps;
  queryClient?: QueryClient;
  includeConfigRules?: boolean;
}

async function coreServerSideProps({
  dehydratedOriginalOpNames,
  authClient: authClientProp,
  context,
  namespaces,
  pageProps,
  queryClient: queryClientProp,
  includeConfigRules,
}: CoreServerSideProps) {
  const authClient = authClientProp || auth(context.req as unknown as Request);
  const queryClient =
    queryClientProp ||
    makeServerQueryClient({
      ...REACT_QUERY_CONFIG,
      serverResponse: context.res,
      authClient,
    });

  const originalLocale = getServerSideSafeLanguage(context);
  const oneLinkConfig = await serverSideOneLinkConfig(queryClient).catch((e) => {
    console.log('Error in serverSideOneLinkConfig: ', e); // eslint-disable-line no-console
    return null;
  });
  const appNameConfig: string = REACT_QUERY_CONFIG.appName;
  const oneLinkProps = getOneLinkProps(
    originalLocale || context.locale,
    appNameConfig,
    context.resolvedUrl,
    oneLinkConfig
  );
  const [translations, { configRules, supportedLanguages }] = await Promise.all([
    serverSideTranslations(oneLinkProps.sstLocale, namespaces, nextI18nextConfig).catch((e) => {
      console.log('Error in serverSideTranslations: ', e); // eslint-disable-line no-console
      return {};
    }),
    getSupportedLanguages({
      client: queryClient,
      path: (context.req as ExpressRequest)?.path,
      locales: context.locales,
    }),
    getSpeedBumpFeatureConfig(queryClient).catch((e) => {
      console.log('Error fetching getSpeedBumpFeatureConfig: ', e); // eslint-disable-line no-console
    }),
  ]);
  queryClient.setQueryData(QUERY_LANGUAGE_KEY, oneLinkProps.sstLocale);
  const featureToggles = [...(pageProps?.featureToggles || [])];

  return {
    ...pageProps,
    ...translations,
    ...oneLinkProps,
    ...(includeConfigRules && { configRules }),
    supportedLanguages,
    featureToggles,
    dehydratedState: getDehydratedQueryState({
      queryClient,
      dehydratedOriginalOpNames,
    }),
  };
}

type IncludedGuestIdProp = { guestId: number };
type OptionalGuestIdProp = { guestId?: never };

type CoreReturnProps = Awaited<ReturnType<typeof coreServerSideProps>>;

type ServerSideProps =
  | (CoreServerSideProps & OptionalGuestIdProp)
  | (CoreServerSideProps & IncludedGuestIdProp);

type ReturnGuestIdProp<T extends ServerSideProps> = T extends { guestId: number }
  ? IncludedGuestIdProp
  : OptionalGuestIdProp;

export async function serverSideProps<T extends ServerSideProps>({ guestId, ...coreProps }: T) {
  const props = await coreServerSideProps(coreProps).catch((e) => {
    console.log('Error in coreServerSideProps: ', e); // eslint-disable-line no-console
    return {};
  });

  return {
    props: {
      ...(!!guestId && { guestId }),
      ...props,
    } as CoreReturnProps & ReturnGuestIdProp<T>,
  };
}
