import { useEffect, useMemo, useRef } from 'react';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useInitConductrics } from '@dx-ui/framework-conductrics';
import { useUserSegments } from '@dx-ui/framework-user-segments';
import type { GuestQuery } from '@dx-ui/framework-auth-provider';

const getConductricsTraits = (
  isAuthenticated: boolean,
  knownGuest: boolean,
  guestInfo?: GuestQuery['guest'] | null
) => {
  const { isTeamMember, isOwner, isOwnerHGV, isLongTenure10, isLongTenure20 } =
    guestInfo?.hhonors || {};
  const { tier } = guestInfo?.hhonors?.summary || {};
  const traits = [`login:${isAuthenticated ? 'yes' : 'no'}`];
  if (tier) {
    traits.push(`tier:${tier.toLowerCase()}`);
  }
  if (knownGuest) {
    traits.push('new-existing-members:existing-members');
  } else {
    traits.push('new-existing-members:new-members');
  }
  const teamMember = isTeamMember || isOwner || isOwnerHGV || isLongTenure10 || isLongTenure20;
  traits.push(`teamMember:${teamMember ? 'yes' : 'no'}`);
  return traits;
};

export const useConductrics = (
  { visitorTraits }: { visitorTraits: string[] } = { visitorTraits: [] }
) => {
  const initConductrics = useInitConductrics();
  const { isLoading, isAuthenticated, guestInfo } = useAuth();
  const loaded = useRef(false);
  const userSegments = useUserSegments();
  const knownGuest = useMemo(() => userSegments?.includes('15217574'), [userSegments]);

  useEffect(() => {
    if (!isLoading && !loaded.current) {
      const guestTraits = getConductricsTraits(isAuthenticated, knownGuest, guestInfo);
      loaded.current = true;
      initConductrics({ traits: [...guestTraits, ...visitorTraits] });
    }
  }, [isAuthenticated, guestInfo, initConductrics, isLoading, knownGuest, visitorTraits]);
};
