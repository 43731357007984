export const ImageClickId = {
  Scroll: 'scroll',
  Img: 'img',
} as const;

export type ImageClickIdType = ValuesOf<typeof ImageClickId>;

export const ImageFunction = {
  NoValue: 'no_value',
  Modal: 'modal',
  Link: 'link',
} as const;

export type ImageFunctionType = ValuesOf<typeof ImageFunction>;

export const ImageType = {
  NoValue: 'no_value',
  ThreeSixNine: '369',
  FourX: '4x',
  Card: 'card',
  Carousel: 'carousel',
  Call: 'call',
  Full: 'full',
  Half: 'half',
  Head: 'head',
  Hero: 'hero',
} as const;

export type ImageTypeType = ValuesOf<typeof ImageType>;

// Common fields for all Image components
export type BaseImageMetrics = {
  click: {
    clickId: ImageClickIdType;
  };
  image: {
    itemTitle?: string;
    headline?: string;
    position?: number;
    count?: number;
    function?: ImageFunctionType;
    type?: ImageTypeType;
  };
};
