import cx from 'classnames';
import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { Link } from '@dx-ui/osc-link';
import type { SocialMediaLink } from './footer.types';
import { useIsClient } from 'usehooks-ts';

type LinkWithExperimentationConfiguration = Link & {
  experimentationConfiguration?: CmsExperimentationConfiguration;
};

export type Footer = {
  /** Allows for a fluid width layout */
  isFluid?: boolean;
  /** The customer support link to use. */
  customerSupportLink?: Link;
  /** The contact number for the help desk */
  contactPhoneLink?: Link;
  /** The links displayed horizontally at bottom */
  legalLinks?: Link[];
  /** The main footer links to be displayed in vertical columns divided equally */
  links?: LinkWithExperimentationConfiguration[];
  /** Social media links to be displayed horizontally under contact information */
  socialLinks?: SocialMediaLink[];
  /** Theme choice for footer */
  theme?: Exclude<CmsBrandComponentTheme, 'light'>;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * A shared footer implementation that is intended to used across OHW UI apps to give a consistent experience for customers to navigate key links.
 *
 * All links within the footer are [Link](/docs/library-components-link--default-story) components.
 */
export const Footer = React.forwardRef<HTMLDivElement, Footer>(
  (
    {
      customerSupportLink,
      isFluid = false,
      legalLinks,
      links: footerLinks = [],
      contactPhoneLink,
      socialLinks,
      theme,
      ...rest
    },
    forwardedRef
  ) => {
    const isClient = useIsClient();
    const teConsentRef = React.useRef<HTMLDivElement>(null);
    const { t } = useTranslation('osc-footer');
    const links = React.useMemo(
      () =>
        [
          ...footerLinks,
          isClient
            ? ({
                id: 'teconsent',
                url: '',
                ref: teConsentRef,
              } as unknown as Link)
            : null,
        ].filter(Boolean) as LinkWithExperimentationConfiguration[],
      [footerLinks, isClient]
    );

    const footerNavLength = links.length;
    const footerNavHalfPoint = footerNavLength > 6 ? Math.ceil(footerNavLength * 0.5) : 0;
    const footerNavFirstHalf = links.slice(0, footerNavHalfPoint);
    const footerNavSecondHalf = links.slice(footerNavHalfPoint, footerNavLength);

    const isDark = theme === 'dark';

    React.useEffect(() => {
      if (!isClient) return;
      if (!teConsentRef.current) return;
      const callback: MutationCallback = (mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            teConsentRef.current?.parentElement?.removeAttribute('aria-hidden');
          }
        }
      };
      const observer = new MutationObserver(callback);
      observer.observe(teConsentRef.current, { childList: true, subtree: true });
      return () => observer.disconnect();
    }, [isClient]);

    return (
      <footer
        className={cx('border-border border-t border-solid', {
          'bg-bg-dark text-text-inverse': isDark,
        })}
        data-testid="osc-footer"
        ref={forwardedRef}
        {...rest}
      >
        <div
          className={cx('pb-8 pt-4', {
            'container-fluid': isFluid,
            container: !isFluid,
          })}
        >
          <div className="mb-10 flex flex-col lg:flex-row">
            <div className="lg:w-1/2">
              <h2 data-osc-product="footer-help-label" className="text-lg">
                {t('helpMessage')}
              </h2>
              <div className="md:divide-border mt-4 md:flex md:divide-x rtl:divide-x-reverse">
                {!!contactPhoneLink && (
                  <div
                    className={cx(
                      'md:px-4 ltr:first:pl-0 ltr:last:pr-0 rtl:first:pr-0 rtl:last:pl-0',
                      {
                        '[&_button#call-me-modal-trigger]:!text-primary [&_#call-me-modal-trigger_span]:!text-primary [&_button#call-me-modal-trigger]:!bg-bg [&_button#call-me-modal-trigger]:hover:!bg-bg-alt [&_#call-me-number-link_span]:!text-text-inverse [&_#call-me-global-support-link]:!text-text-inverse [&_#call-me-global-support-link_+_svg]:!fill-text-inverse':
                          isDark,
                      }
                    )}
                    {...(isClient && { id: 'callme-wrapper' })}
                  >
                    <Link
                      {...contactPhoneLink}
                      underline={false}
                      className={cx('whitespace-nowrap text-lg font-bold uppercase', {
                        'text-primary hover:text-primary-alt': !theme,
                        'text-text-inverse hover:text-bg-alt': isDark,
                      })}
                    >
                      <span className="sr-only">{t('phone')}</span>
                      {contactPhoneLink.label}
                    </Link>
                    <p
                      className={cx('text-sm', {
                        'text-text-alt': !theme,
                        'text-text-inverse': isDark,
                      })}
                    >
                      {t('callMessage')}
                    </p>
                  </div>
                )}
                {!!customerSupportLink && (
                  <div className="mt-4 md:mt-0 md:px-4">
                    <Link
                      {...customerSupportLink}
                      underline={false}
                      className="text-primary hover:text-primary-alt whitespace-nowrap text-lg font-bold"
                    >
                      {customerSupportLink.label || t('customerSupport')}
                    </Link>
                    <p className="text-text-alt text-sm">{t('assistance')}</p>
                  </div>
                )}
              </div>
              {socialLinks && socialLinks?.length > 0 && (
                <div className="py-4 lg:pt-8">
                  <ul className="flex items-center space-x-4 rtl:space-x-reverse">
                    {socialLinks.map(({ channel, url }) => {
                      return (
                        <li key={`footer-social-${channel}`}>
                          <a
                            href={url}
                            className="block"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={`${channel}, ${t('newTab')}`}
                          >
                            <img
                              alt={channel}
                              className="size-8"
                              src={`/modules/assets/svgs/social/${channel}.svg`}
                            />
                            <span className="sr-only">, {t('newTab')}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
            <div className="mt-8 w-full md:flex md:space-x-20 lg:mt-0 lg:w-2/4 lg:justify-end lg:space-x-8 rtl:space-x-reverse">
              {footerNavFirstHalf.length > 0 && (
                <ul>
                  {footerNavFirstHalf.map(
                    ({
                      adaDescription,
                      isNewWindow,
                      label,
                      experimentationConfiguration,
                      ...props
                    }) => (
                      <li
                        className="mb-2"
                        key={label || props?.id || props?.url}
                        {...handleTeconsentParentProps(props)}
                      >
                        <Link
                          {...props}
                          anchorClassName={cx(props.anchorClassName, {
                            'text-text-inverse *:!text-text-inverse': isDark,
                          })}
                          adaDescription={adaDescription}
                          isNewWindow={isNewWindow}
                          data-conductrics-goal={experimentationConfiguration?.goal}
                          data-conductrics-value={experimentationConfiguration?.value}
                        >
                          {label}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              )}
              {footerNavSecondHalf.length > 0 && (
                <ul>
                  {footerNavSecondHalf.map(
                    ({
                      adaDescription,
                      isNewWindow,
                      label,
                      experimentationConfiguration,
                      ...props
                    }) => (
                      <li
                        className="mb-2"
                        key={label || props.url || props?.id}
                        {...handleTeconsentParentProps(props)}
                      >
                        <Link
                          {...props}
                          className={cx('text-primary hover:text-primary-alt text-sm', {
                            '*:text-primary': !theme,
                            '*:text-text-inverse': isDark,
                          })}
                          anchorClassName={cx({
                            'text-text-inverse *:!text-text-inverse': isDark,
                          })}
                          adaDescription={adaDescription}
                          isNewWindow={isNewWindow}
                          data-conductrics-goal={experimentationConfiguration?.goal}
                          data-conductrics-value={experimentationConfiguration?.value}
                        >
                          {label}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          </div>
          {legalLinks && legalLinks.length > 0 && (
            <div>
              <ul className="divide-border flex flex-wrap divide-x rtl:divide-x-reverse">
                {legalLinks.map(({ adaDescription, label, isNewWindow, ...props }) => (
                  <li
                    className="mt-2 px-4 ltr:first:pl-0 rtl:first:pr-0 rtl:last:px-0"
                    key={label || props.url || props?.id}
                  >
                    <Link
                      {...props}
                      className={cx({
                        'text-text-inverse': isDark,
                      })}
                      adaDescription={adaDescription}
                      isNewWindow={isNewWindow}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <p
            className={cx('text-text-alt text-sm', {
              'pt-2': legalLinks && legalLinks.length > 0,
              'text-text-inverse': isDark,
            })}
            suppressHydrationWarning
          >
            &copy;{new Date().getFullYear()} Hilton
          </p>
        </div>
      </footer>
    );
  }
);

function handleTeconsentParentProps(props: Link) {
  if (props.id === 'teconsent') {
    return {
      'aria-hidden': true,
    };
  }
}

Footer.displayName = 'Footer';

export default Footer;
