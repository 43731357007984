import { FormError, FormHelpMessage, FormLabel } from '@dx-ui/osc-form';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useId, useMemo } from 'react';
import { get, useFormContext } from 'react-hook-form';

import { AutoComplete } from './auto-complete';
import { phone } from './form-validation-regex';

import type { FieldError } from 'react-hook-form';
import type { Brand_CountriesQuery } from './queries/generated/types';

type Options = Brand_CountriesQuery['countries'][number];

type Props = {
  containerClassName?: string;
  classname?: string;
  defaultValue?: string;
  label?: string;
  labelClassName?: string;
  loading?: boolean;
  options: Options[];
  required?: boolean;
  personalInformationPage?: boolean;
  name?: string;
  addDefaultOption?: boolean;
  isDisabled?: boolean;
  phoneCountryError?: string;
};

export const FormInputPhone: React.FC<React.PropsWithChildren<Props>> = ({
  required,
  options,
  containerClassName,
  classname,
  labelClassName,
  defaultValue,
  label,
  loading,
  personalInformationPage,
  name = 'phone',
  addDefaultOption,
  isDisabled,
  phoneCountryError,
}) => {
  const id = useId();
  const helperId = `input-helper-${id}`;
  const helperPhoneId = `input-helper-phone-${id}`;
  const errorId = `input-error-${id}`;
  const [t] = useTranslation('osc-join-form-form');
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const phoneNumberFieldError: FieldError = get(errors, `${name}.phoneNumber`);
  const phoneCountryFieldError: FieldError = get(errors, `${name}.phoneCountry`);
  const hasError = !!phoneNumberFieldError || !!phoneCountryFieldError;

  const getOptions = useMemo(
    () =>
      options?.map(({ name, code, callingCode }) => (
        <option
          key={`option-${code}`}
          value={`${callingCode}${code}`}
          {...(`${callingCode}${code}` === defaultValue ? { selected: true } : {})}
        >{`+${callingCode} ${name}`}</option>
      )),
    [defaultValue, options]
  );

  return (
    <div
      className={cx({
        [containerClassName as string]: !!containerClassName,
      })}
    >
      <div
        className={cx('flex flex-col gap-y-4', {
          'lg:hidden': personalInformationPage,
          hidden: !personalInformationPage,
        })}
      >
        <FormLabel
          label={t('phone.phoneCountryLabel')}
          required={required}
          hasError={!!phoneCountryFieldError}
          className={cx({
            [labelClassName as string]: !!labelClassName,
          })}
        >
          <span className={cx('flex flex-col gap-y-4')}>
            <select
              defaultValue={defaultValue}
              autoComplete={AutoComplete.COUNTRY}
              className={cx('form-select w-1/3 overflow-hidden focus:relative focus:z-0', {
                'w-1/3': !personalInformationPage,
                'w-full lg:w-1/3': personalInformationPage,
                'form-error': !!phoneCountryFieldError,
                disabled: loading,
                [classname as string]: !!classname,
              })}
              disabled={loading}
              data-testid="phone.phoneCountry"
              aria-invalid={!!phoneCountryFieldError}
              aria-describedby={helperId}
              {...register(`${name}.phoneCountry`, {
                required: { value: true, message: t('phone.phoneCountryError') },
              })}
            >
              {addDefaultOption ? (
                <option key="selectCountry" value="" disabled>
                  {t('phone.phoneCountrySelectOption')}
                </option>
              ) : null}

              {options?.map(({ name, code, callingCode }) => (
                <option
                  key={`option-${code}`}
                  value={`${callingCode}${code}`}
                >{`+${callingCode} ${name}`}</option>
              ))}
            </select>
          </span>
        </FormLabel>
        {phoneCountryFieldError && <FormError id={errorId} error={phoneCountryFieldError} />}

        <FormLabel
          label={label || t('phone.label')}
          required={required}
          hasError={!!phoneNumberFieldError}
          className={cx({
            [labelClassName as string]: !!labelClassName,
          })}
        >
          <span className={cx('flex flex-col gap-y-4 lg:flex-row')}>
            <input
              autoComplete={AutoComplete.TEL}
              type="tel"
              className={cx('form-input -ml-0.5 -mt-2 flex-1 focus:relative focus:z-0 lg:mt-0', {
                'form-error': !!phoneNumberFieldError,
                [classname as string]: !!classname,
              })}
              aria-invalid={!!phoneNumberFieldError}
              aria-describedby={`${errorId} ${helperId}`}
              data-testid="phone.phoneNumber"
              maxLength={15}
              {...register(`${name}.phoneNumber`, {
                required: { value: true, message: t('phone.phoneNumberError') },
                pattern: { value: phone, message: t('phone.phoneNumberError') },
              })}
            />
          </span>
        </FormLabel>
        {phoneNumberFieldError && <FormError id={errorId} error={phoneNumberFieldError} />}
        <FormHelpMessage id={helperId} loading={loading} />
      </div>
      <div className={cx({ 'hidden lg:block': personalInformationPage })}>
        <FormLabel
          label={label || t('phone.label')}
          required={required}
          hasError={hasError}
          className={cx({
            [labelClassName as string]: !!labelClassName,
          })}
        >
          <span
            className={cx('flex', {
              'flex-row': !personalInformationPage,
              'flex-col lg:flex-row gap-y-4': personalInformationPage,
            })}
          >
            <select
              defaultValue={defaultValue}
              autoComplete={AutoComplete.COUNTRY}
              className={cx('form-select w-1/3 overflow-hidden focus:relative focus:z-0', {
                'w-1/3': !personalInformationPage,
                'w-full lg:w-1/3': personalInformationPage,
                'form-error': !!phoneCountryFieldError,
                disabled: loading || isDisabled,
                [classname as string]: !!classname,
              })}
              disabled={loading || isDisabled}
              data-testid="phone.phoneCountry"
              aria-invalid={!!phoneCountryFieldError}
              aria-describedby={helperId}
              {...register(`${name}.phoneCountry`, {
                required: {
                  value: true,
                  message: phoneCountryError ? phoneCountryError : t('phone.phoneCountryError'),
                },
              })}
            >
              {addDefaultOption ? (
                <option key="selectCountry" value="" disabled>
                  {t('phone.phoneCountrySelectOption')}
                </option>
              ) : null}
              {getOptions}
            </select>

            <input
              autoComplete={AutoComplete.TEL}
              type="tel"
              className={cx('form-input -ml-0.5 flex-1 focus:relative focus:z-0', {
                '-mt-2 lg:mt-0': personalInformationPage,
                'form-error': !!phoneNumberFieldError,
                [classname as string]: !!classname,
              })}
              aria-invalid={!!phoneNumberFieldError}
              aria-describedby={`${errorId} ${helperPhoneId}`}
              data-testid="phone.phoneNumber"
              maxLength={15}
              {...register(`${name}.phoneNumber`, {
                required: { value: true, message: t('phone.phoneNumberError') },
                pattern: { value: phone, message: t('phone.phoneNumberError') },
              })}
              disabled={isDisabled}
            />
          </span>
        </FormLabel>
        {phoneCountryFieldError && <FormError id={errorId} error={phoneCountryFieldError} />}
        {phoneNumberFieldError && <FormError id={errorId} error={phoneNumberFieldError} />}
        <FormHelpMessage id={helperId} loading={loading} />
        <FormHelpMessage
          id={helperPhoneId}
          loading={loading}
          message={label || t('phone.label')}
          className="!hidden"
        />
      </div>
    </div>
  );
};
