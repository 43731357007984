import type { GuestHHonorsPackage } from '@dx-ui/gql-types';

/**
Check a guest’s product codes against a predefined list to see if any match
@param {string[]} guestPackages - Packages from guestInfo.hhonors.packages 
@param {string[]} productCodes - List of product codes to check against
@example
// Returns true
hasValidProductCode(["HGD", "TMH"], ["HGD", "XYZ", "ABC"])
@example
// Returns false
hasValidProductCode(["HGD", "TMH"], ["XYZ", "ABC"])
*/
export const hasValidProductCode = (
  guestPackages: Pick<GuestHHonorsPackage, 'packageName'>[],
  productCodes: string[]
): boolean => {
  if (!guestPackages || !productCodes || guestPackages.length === 0 || productCodes.length === 0)
    return false;
  return guestPackages?.filter(({ packageName }) => productCodes.includes(packageName))?.length > 0;
};
