export const Goals = {
  CreditCardApplyNow: 'g-credit-card-apply-now',
  HonorsApplyNow: 'g-honors-apply-now',
  SurpassApplyNow: 'g-surpass-apply-now',
  AspireApplyNow: 'g-aspire-apply-now',
  BusinessApplyNow: 'g-business-apply-now',
  CreditCardTermsAndConditions: 'g-credit-card-terms-and-conditions',
  SuccessfulHonorsEnrollment: 'g-successful-honors-enrollment',
  FindAHotel: 'g-8xd5mdahgj',
  FindAHotelMCLP: 'FindAHotel-MCLP',
  LearnMoreCtaClick: 'g-LearnMoreCtaClick',
  TierTabSelection: 'g-tierTabSelection',
  EditSearchWidget: 'g-EditGuestSearchWidget',
  BookYourNextGetaway: 'g-book-your-next-getaway',
  PopUpCloseClick: 'g-x-exit',
} as const;
