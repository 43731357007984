import type { DigitalData } from '@dx-ui/config-metrics';

export const digitalData: DigitalData = {
  event: [],
  user: [
    {
      profile: [
        {
          profileInfo: {
            profileID: '',
            pointsBalance: '',
            rewardsTier: '',
          },
          attributes: {
            loginStatus: 'Logged-out',
            goUserType: '',
          },
        },
      ],
    },
  ],
  page: {
    attributes: {
      siteExperience: 'R',
    },
    category: {
      siteType: 'B',
    },
    pageInfo: {},
  },
  global: {
    page: {},
    user: {
      profileID: '',
      rewardsTier: '',
      userType: '',
    },
  },
};

export const defaultPageData = {
  brandCode: 'HH',
  brandName: 'Hilton Honors',
  exp: 'Browser',
  expType: 'New Hilton',
  pageName: 'Home',
  primaryCategory: 'honors',
  siteType: 'B',
  subSection: null,
  subSubSection: null,
  isCmsPage: false,
};
