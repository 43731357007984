import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import {
  useFormContext,
  type FieldError,
  type FieldValues,
  type UseFormGetValues,
} from 'react-hook-form';

import { FormError } from '@dx-ui/osc-form';
import { InfoPopup } from '@dx-ui/osc-info-popup';

const GROUP_LIMIT = 10;

const InputGroup: React.FC<{
  id: string;
  label?: string;
  info?: React.ReactNode;
  isCentered?: boolean;
  error: Partial<FieldError> | undefined;
  children: React.ReactNode;
}> = ({ id, label, info, error, isCentered, children }) => {
  return (
    <div className="flex-1">
      <div
        className={cx('label flex flex-1 flex-col items-start md:grow-0', {
          'items-start': !isCentered,
          'sm:items-center': isCentered,
          'text-danger': error,
        })}
      >
        <div className="flex items-center">
          {label ? (
            <label
              data-osc-product="shop-input-label"
              htmlFor={id}
              className="whitespace-nowrap pr-1"
            >
              {label}
            </label>
          ) : null}
          {info ? <InfoPopup>{info}</InfoPopup> : null}
        </div>
        {children}
      </div>
      <FormError error={error} className="lg:absolute" />
    </div>
  );
};

export type ShopFormGroupProps = {
  showNumAttendees?: boolean;
};
export const ShopFormGroup: React.FC<React.PropsWithChildren<ShopFormGroupProps>> = (
  props: ShopFormGroupProps
) => {
  const { t } = useTranslation('osc-rooms');
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    trigger,
  } = useFormContext();

  const getNumRoomsValidation = (numRooms: string, getValues: UseFormGetValues<FieldValues>) => {
    const numAttendees = parseInt(getValues('numAttendees'));
    if (parseInt(numRooms) < 0) return t('roomsAttendees.numRoomsEmptyError');
    if (getValues('meetingSpace') && numAttendees > 0) return true;
    if (!getValues('meetingSpace') && (parseInt(numRooms) === 0 || isNaN(parseInt(numRooms))))
      return t('roomsAttendees.numRoomsEmptyError');
    return true;
  };

  const getNumAttendeesValidation = (
    numAttendees: string,
    getValues: UseFormGetValues<FieldValues>
  ) => {
    if (parseInt(numAttendees) > 0 || !getValues('meetingSpace')) return true;
    return t('roomsAttendees.numAttendeeEmptyError');
  };

  return (
    <div className="flex flex-auto flex-col gap-2 sm:flex-row lg:gap-3">
      <InputGroup
        id="shop-form-numRooms"
        label={t('roomsAttendees.numRooms')}
        error={errors?.numRooms}
      >
        <input
          id="shop-form-numRooms"
          type="number"
          min={0}
          className={cx('form-input w-full', {
            'form-error': errors?.numRooms?.message,
          })}
          {...register('numRooms', {
            value: GROUP_LIMIT,
            validate: {
              numRoomsValidation: (numRooms) => getNumRoomsValidation(numRooms, getValues),
            },
            valueAsNumber: true,
          })}
        />
      </InputGroup>
      {props.showNumAttendees ? (
        <>
          <InputGroup isCentered id="shop-form-meetingSpace" error={errors?.meetingSpace}>
            <div className="flex min-w-max flex-row-reverse sm:pt-8">
              <label htmlFor="shop-form-meetingSpace" className="ms-2 whitespace-nowrap">
                {t('roomsAttendees.meetingSpace')}
              </label>
              <input
                id="shop-form-meetingSpace"
                type="checkbox"
                className={cx('form-checkbox', {
                  'form-error': errors?.meetingSpace,
                })}
                onClick={() => {
                  if (!getValues('meetingSpace')) setValue('numAttendees', 1);
                  else setValue('numAttendees', 0);
                }}
                {...register('meetingSpace', {
                  onChange: async () => {
                    await trigger('numRooms');
                    await trigger('numAttendees');
                  },
                })}
              />
            </div>
          </InputGroup>
          <InputGroup
            id="shop-form-numAttendees"
            error={errors?.numAttendees}
            label={t('roomsAttendees.numAttendees')}
          >
            <input
              id="shop-form-numAttendees"
              type="number"
              className={cx('form-input w-full', {
                'form-error': errors?.numAttendees,
              })}
              min={0}
              {...register('numAttendees', {
                onChange: () => trigger('numRooms'),
                validate: {
                  numAttendeesValidation: (numAttendees) =>
                    getNumAttendeesValidation(numAttendees, getValues),
                },
              })}
              disabled={!getValues('meetingSpace')}
            />
          </InputGroup>
        </>
      ) : null}
    </div>
  );
};
