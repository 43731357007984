import { useState } from 'react';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

export type UseScrolledOptions = {
  preventListeners?: boolean;
  attachToWindow?: boolean;
};

type ScrolledState = {
  scrollTop: number;
  scrollLeft: number;
};

const defaultOptions = {
  preventListeners: false,
  attachToWindow: false,
};

const mergeOptions = (options?: UseScrolledOptions) => ({
  ...defaultOptions,
  ...(options || {}),
});

export const useScrolled = (
  ref: React.RefObject<HTMLElement> | null,
  options?: UseScrolledOptions
): ScrolledState => {
  const { preventListeners, attachToWindow } = mergeOptions(options);
  const [{ scrollTop, scrollLeft }, setScrolled] = useState<ScrolledState>({
    scrollTop: 0,
    scrollLeft: 0,
  });

  const handleScroll = () => {
    let scrollTopVal = 0;
    let scrollLeftVal = 0;
    if (attachToWindow) {
      scrollTopVal = window.pageYOffset;
      scrollLeftVal = window.pageXOffset;
    }
    const element = ref && ref.current;
    if (!attachToWindow && element) {
      scrollTopVal = element.scrollTop;
      scrollLeftVal = element.scrollLeft;
    }
    setScrolled({ scrollTop: scrollTopVal, scrollLeft: scrollLeftVal });
  };

  useIsomorphicLayoutEffect(() => {
    const element = attachToWindow ? window : ref && ref.current;
    element && element.addEventListener('scroll', handleScroll);
    if (preventListeners) {
      element && element.removeEventListener('scroll', handleScroll);
    }
    return () => {
      element && element.removeEventListener('scroll', handleScroll);
    };
  }, [ref && ref.current && preventListeners && attachToWindow]);

  return {
    scrollLeft,
    scrollTop,
  };
};
