import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { TDrawerItem, TDrawerList } from '../types';
import { Link } from '@dx-ui/osc-link';
import Icon from '@dx-ui/osc-icon';
import { trackNavClickBasedOnLabel } from '../header.utilities';
import cx from 'classnames';

export const DrawerList = ({ menuItems, isOpen, onClose }: TDrawerList) => {
  const [nextItem, setNextItem] = useState<TDrawerItem | undefined>();
  const [nextItemIndex, setNextItemIndex] = useState<number>();
  const [selectedItem, setSelectedItem] = useState<TDrawerItem | undefined>();
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>();
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const lastSelectedButton = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('osc-header');

  useEffect(() => {
    if (!isOpen) setSelectedItem(undefined);
  }, [isOpen]);

  const goBack = () => {
    setIsFadingOut(true);
    setNextItem(undefined);
    setTimeout(() => {
      lastSelectedButton?.current?.focus();
    }, 350);
  };

  const focusOnBack = () => {
    setTimeout(() => {
      backButtonRef?.current?.focus();
    }, 50);
  };

  const itemSelected = (menuItem: TDrawerItem, index: number) => {
    setIsFadingOut(true);
    setNextItem(menuItem);
    setNextItemIndex(index);
  };

  return (
    <div
      className={isFadingOut ? 'animate-fadeout opacity-0' : 'animate-fadein'}
      onAnimationEnd={() => {
        if (isFadingOut) {
          setIsFadingOut(false);
          setSelectedItem(nextItem);
          setSelectedItemIndex(nextItemIndex);
          focusOnBack();
        }
      }}
    >
      <div className="border-border brand-ey:border-b-primary-alt brand-ou:border-b-primary relative border-b px-4 py-3">
        <button onClick={onClose} type="button" className="px-2">
          <Icon name="close" className="m-auto" />
          <span className="brand-ey:font-normal brand-wa:font-normal text-xs font-semibold">
            {t('close')}
            <span className="sr-only">{t('menu')}</span>
          </span>
        </button>
      </div>
      {selectedItem ? (
        <button
          ref={backButtonRef}
          onClick={goBack}
          className={cx(
            'bg-bg-alt nav-main-link border-primary flex h-12 w-full items-center border-s-8 pe-2 ps-4 text-left',
            'brand-ey:bg-tertiary-alt brand-ey:border-b-primary-alt brand-ey:border-b',
            'brand-hi-refresh:bg-bg-light',
            'brand-lx:bg-bg-light',
            'brand-ou:bg-bg-light brand-ou:border-b-primary brand-ou:border-b'
          )}
          aria-expanded="true"
          type="button"
        >
          <Icon name="arrowhead-small-right" className="me-3 rotate-180 rtl:rotate-0" />
          <span>{selectedItem.label}</span>
        </button>
      ) : null}

      {selectedItem ? (
        <ul>
          {selectedItem.subMenu?.map((menuItem) =>
            menuItem.link?.url ? (
              <li key={menuItem.label}>
                <Link
                  anchorClassName="block nav-drawer-link brand-ey:focus:bg-tertiary-alt brand-lx:focus:bg-bg-light brand-ou:focus:bg-bg-light"
                  underline={false}
                  className={cx(
                    'border-border flex h-12 items-center border-b ps-4',
                    'brand-ey:hover:bg-tertiary-alt brand-ey:border-b-primary-alt',
                    'brand-hi-refresh:hover:bg-bg-light brand-hi-refresh:focus:bg-bg-light',
                    'brand-lx:hover:bg-bg-light',
                    'brand-ou:hover:bg-bg-light brand-ou:border-b-primary'
                  )}
                  url={menuItem.link.url}
                  isNewWindow={menuItem.link.isNewWindow}
                  onClick={() => {
                    trackNavClickBasedOnLabel(menuItem.label, selectedItem.label);
                    menuItem.onClick?.();
                  }}
                  data-conductrics-goal={menuItem.link.experimentationConfiguration?.goal}
                  data-conductrics-value={menuItem.link.experimentationConfiguration?.value}
                >
                  {menuItem.label}
                </Link>
              </li>
            ) : null
          )}
        </ul>
      ) : (
        // If no submenu is currently selected, show main set of menu items
        <ul>
          {menuItems.map((menuItem, index) => {
            if (menuItem?.subMenu?.length) {
              return (
                <li key={menuItem.label}>
                  <button
                    ref={selectedItemIndex === index ? lastSelectedButton : null}
                    onClick={() => {
                      itemSelected(menuItem, index);
                      trackNavClickBasedOnLabel(menuItem.label);
                    }}
                    className={cx(
                      'nav-main-link border-border animate-fadein flex h-12 w-full items-center justify-between border-b ps-4 text-left',
                      'brand-ey:hover:bg-tertiary-alt brand-ey:border-b-primary-alt brand-ey:focus:bg-tertiary-alt',
                      'brand-hi-refresh:hover:bg-bg-light brand-hi-refresh:focus:bg-bg-light',
                      'brand-lx:hover:bg-bg-light brand-lx:focus:bg-bg-light',
                      'brand-ou:hover:bg-bg-light brand-ou:border-b-primary brand-ou:focus:bg-bg-light'
                    )}
                    aria-expanded="false"
                    type="button"
                    data-conductrics-goal={menuItem.link?.experimentationConfiguration?.goal}
                    data-conductrics-value={menuItem.link?.experimentationConfiguration?.value}
                  >
                    <span>{menuItem.label}</span>
                    <Icon name="arrowhead-small-right" className="me-3 ms-auto rtl:rotate-180" />
                  </button>
                </li>
              );
            } else if (menuItem.link?.url) {
              return (
                <li key={menuItem.label}>
                  <Link
                    anchorClassName="block brand-ey:focus:bg-tertiary-alt brand-ou:focus:bg-bg-light"
                    underline={false}
                    className={cx(
                      'nav-main-link border-border flex h-12 items-center border-b ps-4',
                      'brand-ey:hover:bg-tertiary-alt brand-ey:border-b-primary-alt',
                      'brand-hi-refresh:hover:bg-bg-light brand-hi-refresh:focus:bg-bg-light',
                      'brand-ou:hover:bg-bg-light brand-ou:border-b-primary'
                    )}
                    url={menuItem.link.url}
                    isNewWindow={menuItem.link.isNewWindow}
                    onClick={() => {
                      trackNavClickBasedOnLabel(menuItem.label);
                      menuItem.onClick?.();
                    }}
                    data-conductrics-goal={menuItem.link.experimentationConfiguration?.goal}
                    data-conductrics-value={menuItem.link.experimentationConfiguration?.value}
                  >
                    {menuItem.label}
                  </Link>
                </li>
              );
            }
            return null;
          })}
        </ul>
      )}
    </div>
  );
};
