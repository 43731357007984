import { createMetrics, metricsConfig } from '@dx-ui/config-metrics';
import { env } from '@dx-ui/framework-env';

import { digitalData } from '../../config/metrics/digitalData';
import { ExtendedAdobeTagManager } from './ExtendedAdobeTagManager';

const config: { api: ExtendedAdobeTagManager } = createMetrics(
  metricsConfig({
    digitalData,
    debug: Boolean(env('TRACKING_DEBUG') && /stg|test|local/.test(env('APP_ENV'))),
    Interface: ExtendedAdobeTagManager,
    seedFile: env('TRACKING_SEED_FILE'),
    server: typeof window === 'undefined',
  })
);

export default config;
