import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

import { FormError } from './form.error';

import type { SubmitHandler, UseFormProps, UseFormReturn } from 'react-hook-form';
import type { FormDataStructure } from './form.types';

type FormProps = {
  /**
   * This is an override to the main form error message, used to notify the user that there are errors that need to be fixed. This is not for individual field error messages.
   */
  errorMessage?: string;
  /**
   * This will return an object with key/value pairs for each input in the form
   */
  onSubmit?: (data: any, methods: UseFormReturn) => void;
  /**
   * Please reference the [react-hook-form `useForm()` API documentation](https://react-hook-form.com/api/useform)
   */
  useFormProps?: UseFormProps<FormDataStructure>;
  /** Override for the Error Banner Defaults to displaying */
  hasErrorBanner?: boolean | undefined;
} & Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'>;

/**
 * This is the Form Component that sets up `react-hook-form` with a `<FormProvider/>` and a general error message. All inputs in the form should `useFormContext()` either using `FormInput, FormSelect, FormTextarea, FormConnector` or some other custom component.
 */
export const Form: React.FC<React.PropsWithChildren<FormProps>> = ({
  errorMessage,
  onSubmit,
  children,
  useFormProps = { mode: 'onTouched', criteriaMode: 'all' },
  hasErrorBanner = true,
  method = 'POST',
  ...rest
}) => {
  const [t] = useTranslation('osc-form');
  const memoizedFormProps = React.useMemo(() => useFormProps, [useFormProps]);

  const methods = useForm<FormDataStructure>({
    mode: 'onTouched',
    criteriaMode: 'all',
    ...memoizedFormProps,
  });
  const {
    formState: { errors, isSubmitted },
  } = methods;
  const hasErrors = Object.keys(errors).length > 0;

  const onFormSubmit: SubmitHandler<FormDataStructure> = (data) => {
    if (onSubmit) {
      onSubmit(data, methods);
    }
  };

  return (
    <FormProvider {...methods}>
      {hasErrorBanner && (
        <div
          role="alert"
          className={cx('bg-danger-alt text-danger', {
            'mb-4 p-4': hasErrors && isSubmitted,
          })}
        >
          {hasErrors && isSubmitted && (
            <h2>
              <FormError
                className="mt-0 font-semibold"
                error={{ message: errorMessage || t('errorMessage') }}
              />
            </h2>
          )}
        </div>
      )}
      <form method={method} {...rest} noValidate onSubmit={methods.handleSubmit(onFormSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

export type { FormProps };
