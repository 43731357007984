import * as React from 'react';
import cx from 'classnames';

type CalendarDay = {
  /**
   * is a date within the selected range
   */
  inRange?: boolean;
  /**
   * applies UI to denote that it is the current date
   */
  isToday?: boolean;
  /**
   * is either the `day` or `endDay`
   */
  selected?: boolean;
  /**
   * applies UI for a highlighted date
   */
  highlighted?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Displays a calendar day as a button
 */
const CalendarDay = React.forwardRef<HTMLButtonElement, CalendarDay>(
  ({ className, inRange, isToday, selected, highlighted, ...rest }, forwardedRef) => (
    <button
      className={cx(
        'disabled:text-text-disabled border-bg focus:border-primary hover:border-primary block h-[37px] w-full min-w-[37px] appearance-none border text-center uppercase ring-inset focus:!shadow-none disabled:cursor-default',
        'brand-hi-refresh:hover:border-none brand-hi-refresh:hover:bg-primary-alt brand-hi-refresh:hover:text-text-inverse',
        {
          'bg-bg-alt text-text': !selected && inRange,
          'bg-bg-alt': !selected && !inRange && isToday,
          'bg-primary text-text-inverse focus:ring-bg focus:border-primary hover:border-primary hover:ring-bg hover:ring-1 focus:ring-1':
            selected,
          'font-bold': isToday,
          'ring-primary ring-1': highlighted,
          className,
        }
      )}
      {...rest}
      type="button"
      ref={forwardedRef}
    />
  )
);

CalendarDay.displayName = 'CalendarDay';

export { CalendarDay };
export default CalendarDay;
