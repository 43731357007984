import { createGlobalStyle, css } from 'styled-components';

type GlobalAssetsProps = {
  language: string;
};

const fontFace = (
  family: string,
  weight: number | null,
  sources: { url: string; format: string }[]
) => css`
  @font-face {
    font-family: '${family}';
    font-display: swap;
    ${weight && `font-weight: ${weight};`}
    font-display: swap;
    src: ${sources.map(({ url, format }) => `url(${url}) format('${format}')`).join(', ')};
  }
`;

export const GlobalStyle = createGlobalStyle<GlobalAssetsProps>`
    ${({ language }) => {
      let url = '/modules/assets/fonts/proxima-nova/regular.woff2';
      if (language === 'ko') url = '/modules/assets/fonts/yd-gothic/regular.woff2';
      if (language === 'th') url = '/modules/assets/fonts/neue-frutiger-thai/light.woff2';
      if (language === 'ar') url = '/modules/assets/fonts/sans-arabic/regular.woff2';
      return fontFace('Proxima N W01 Reg', null, [{ url, format: 'woff2' }]);
    }}

    @font-face {
      font-family: 'Proxima N W01 Smbd';
		  font-display: swap;
      src: url(${() => '/modules/assets/fonts/proxima-nova/semibold.woff2'}) format('woff2'), 
        url(${() => '/modules/assets/fonts/proxima-nova/semibold.woff'}) format('woff');
    }

    @font-face {
      font-family: 'Proxima N W01 Xbold';
		  font-display: swap;
      src: url(${() => '/modules/assets/fonts/proxima-nova/extrabold.woff2'}) format('woff2'), 
        url(${() => '/modules/assets/fonts/proxima-nova/extrabold.woff'}) format('woff');
    }
    ${({ language }) => {
      let url = '/modules/assets/fonts/proxima-nova/bold.woff2';
      let format = 'woff2';
      if (language === 'ko') url = '/modules/assets/fonts/yd-gothic/regular.woff2';
      if (language === 'th') url = '/modules/assets/fonts/neue-frutiger-thai/regular.woff2';
      if (language === 'ar') {
        url = '/modules/assets/fonts/sans-arabic/bold.woff';
        format = 'woff';
      }
      return fontFace('Proxima', 600, [{ url, format }]);
    }}

    @font-face {
      font-family: 'Loew W00 Regular';
		  font-display: swap;
      src: url(${() => '/modules/assets/fonts/loew/regular.woff2'}) format('woff2'), 
        url(${() => '/modules/assets/fonts/loew/regular.woff'}) format('woff');
    }

    @font-face {
      font-family: 'Loew W00 Medium';
		  font-display: swap;
      src: url(${() => '/modules/assets/fonts/loew/medium.woff2'}) format('woff2'), 
        url(${() => '/modules/assets/fonts/loew/medium.woff'}) format('woff');
    }

    @font-face {
      font-family: 'Loew W00 Bold';
		  font-display: swap;
      src: url(${() => '/modules/assets/fonts/loew/bold.woff2'}) format('woff2'), 
        url(${() => '/modules/assets/fonts/loew/bold.woff'}) format('woff');
    }

    @font-face {
      font-family: 'Loew W00 ExtraBold';
		  font-display: swap;
      src: url(${() => '/modules/assets/fonts/loew/extrabold.woff2'}) format('woff2'), 
        url(${() => '/modules/assets/fonts/loew/extrabold.woff'}) format('woff');
    }

    ${({ language }) => {
      let url = '/modules/assets/fonts/loew2/black.woff';
      let format = 'woff';
      if (language === 'ar') {
        url = '/modules/assets/fonts/sans-arabic/black.woff2';
        format = 'woff2';
      }
      return fontFace('Loew W00 Black', null, [{ url, format }]);
    }}

    ${({ language }) => {
      let url = '/modules/assets/fonts/loew/heavy.woff2';
      let format = 'woff2';
      if (language === 'ko') url = '/modules/assets/fonts/yd-gothic/heavy.woff2';
      if (language === 'th') url = '/modules/assets/fonts/neue-frutiger-thai/bold.woff2';
      if (language.includes('zh-han')) {
        format = 'truetype';
        url = '/modules/assets/fonts/fz-chao-cu-hei/regular.ttf';
      }
      return fontFace('Loew', null, [{ url, format }]);
    }}

    @font-face {
      font-family: 'Loew W00 Thin';
		  font-display: swap;
      src: url(${() => '/modules/assets/fonts/loew/thin.woff2'}) format('woff2'), 
        url(${() => '/modules/assets/fonts/loew/thin.woff'}) format('woff');
    }

    ${({ language }) => {
      let url = '/modules/assets/fonts/simple-joys/simple-joys-webfont.woff';
      let format = 'woff2';
      if (language === 'ko') {
        format = 'opentype';
        url = '/modules/assets/fonts/nanum-pen/regular.otf';
      }
      // if (language.includes('zh-han')) url = '/modules/assets/fonts/banquet/medium.woff2';
      if (language === 'th') url = '/modules/assets/fonts/neue-frutiger-thai/bold.woff2';
      if (language === 'ar') {
        url = '/modules/assets/fonts/massira-pen/massira-pen.woff';
        format = 'woff';
      }
      return fontFace('Simple Joys Regular', null, [{ url, format }]);
    }}

    ${() =>
      fontFace('Simple Joys 2 Regular', null, [
        { url: '/modules/assets/fonts/simple-joys-2/simple-joys-2.woff2', format: 'woff2' },
        { url: '/modules/assets/fonts/simple-joys-2/simple-joys-2.woff', format: 'woff' },
      ])}

    ${() =>
      fontFace('Loew 2.0', null, [
        { url: '/modules/assets/fonts/loew2/regular.woff', format: 'woff' },
      ])}

    ${({ language }) => {
      let url = '/modules/assets/fonts/loew2/black.woff';
      const format = 'woff';
      if (language === 'ar') url = '/modules/assets/fonts/sans-arabic/black.woff';
      return fontFace('Loew 2.0 Black', null, [{ url, format }]);
    }}  

    ${({ language }) => {
      let url = '/modules/assets/fonts/loew2/black.woff';
      const format = 'woff';
      if (language === 'ar') url = '/modules/assets/fonts/sans-arabic/black.woff';
      return fontFace('Loew 2.0 Black', null, [{ url, format }]);
    }}  

    ${() =>
      fontFace('Loew 2.0 Black Italic', null, [
        { url: '/modules/assets/fonts/loew2/blackItalic.woff', format: 'woff' },
      ])}
    ${({ language }) => {
      let url = '/modules/assets/fonts/loew2/bold.woff';
      const format = 'woff';
      if (language === 'ar') url = '/modules/assets/fonts/sans-arabic/bold.woff';
      return fontFace('Loew 2.0 Black', null, [{ url, format }]);
    }}  
    ${() =>
      fontFace('Loew 2.0 Bold Italic', null, [
        { url: '/modules/assets/fonts/loew2/boldItalic.woff', format: 'woff' },
      ])}
    ${() =>
      fontFace('Loew 2.0 Extra Bold', null, [
        { url: '/modules/assets/fonts/loew2/extraBold.woff', format: 'woff' },
      ])}
    ${() =>
      fontFace('Loew 2.0 Extra Bold Italic', null, [
        { url: '/modules/assets/fonts/loew2/extraBoldItalic.woff', format: 'woff' },
      ])}
    ${({ language }) => {
      let url = '/modules/assets/fonts/loew2/heavy.woff';
      let format = 'woff';
      if (language === 'ko') url = '/modules/assets/fonts/yd-gothic/heavy.woff2';
      if (language === 'th') url = '/modules/assets/fonts/neue-frutiger-thai/bold.woff2';
      if (language.includes('zh-han')) {
        format = 'truetype';
        url = '/modules/assets/fonts/fz-chao-cu-hei/regular.ttf';
      }
      return fontFace('Loew 2.0 Heavy', null, [{ url, format }]);
    }}  
    ${() =>
      fontFace('Loew 2.0 Heavy Italic', null, [
        { url: '/modules/assets/fonts/loew2/heavyItalic.woff', format: 'woff' },
      ])}
    ${() =>
      fontFace('Loew 2.0 Italic', null, [
        { url: '/modules/assets/fonts/loew2/italic.woff', format: 'woff' },
      ])}
    ${({ language }) => {
      let url = '/modules/assets/fonts/loew2/light.woff';
      const format = 'woff';
      if (language === 'ar') url = '/modules/assets/fonts/sans-arabic/regular.woff';
      return fontFace('Loew 2.0 Light', null, [{ url, format }]);
    }}  
    ${() =>
      fontFace('Loew 2.0 Light Italic', null, [
        { url: '/modules/assets/fonts/loew2/lightItalic.woff', format: 'woff' },
      ])}
    ${() =>
      fontFace('Loew 2.0 Medium', null, [
        { url: '/modules/assets/fonts/loew2/medium.woff', format: 'woff' },
      ])}
    ${() =>
      fontFace('Loew 2.0 Medium Italic', null, [
        { url: '/modules/assets/fonts/loew2/mediumItalic.woff', format: 'woff' },
      ])}
    ${() =>
      fontFace('Loew 2.0 Thin', null, [
        { url: '/modules/assets/fonts/loew2/thin.woff', format: 'woff' },
      ])}
    ${() =>
      fontFace('Loew 2.0 Thin Italic', null, [
        { url: '/modules/assets/fonts/loew2/thinItalic.woff', format: 'woff' },
      ])}
  `;
