import type { QueryClient } from '@tanstack/react-query';
import { dehydrate } from '@tanstack/react-query';

export const getDehydratedQueryState = ({
  queryClient,
  dehydratedOriginalOpNames = [],
}: {
  queryClient: QueryClient;
  dehydratedOriginalOpNames?: string[];
}) => {
  // JSON.parse(JSON.stringify()) is hack to serialize undefined
  // https://github.com/TanStack/query/issues/1458#issuecomment-788447705
  return JSON.parse(
    JSON.stringify(
      dehydrate(queryClient, {
        shouldDehydrateQuery: (query) => {
          const queryKey = query.queryKey[0] as { originalOpName?: string };
          if (dehydratedOriginalOpNames.length) {
            return dehydratedOriginalOpNames.includes(queryKey?.originalOpName || '');
          }
          return true;
        },
      })
    )
  );
};
