import type { Link } from '@dx-ui/osc-link';
import type { Resources } from 'i18next';

export const GlobalFooterLinks = (
  globalFooterLinks: Resources['footer']['globalFooterLinks'],
  locale: string,
  handleClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => boolean
): Link[] =>
  [
    {
      label: globalFooterLinks.webAccessibility,
      url: `/${locale}/p/accessibility`,
      isNewWindow: true,
      onClick: handleClick,
    },
    {
      label: globalFooterLinks.customerSupport,
      url: `https://help.hilton.com/s/`,
      isNewWindow: true,
      onClick: handleClick,
    },
    {
      label: globalFooterLinks.globalPrivacy,
      url: `/${locale}/p/global-privacy-statement/`,
      isNewWindow: true,
    },
    locale === 'en'
      ? {
          label: globalFooterLinks.creditCards,
          url: `/${locale}/hilton-honors/credit-cards/?cid=OH,,GlobalFooterCobrandAllCardsAcqAlwaysOn,MultiBR,,Home,SingleLink,i83471`,
          isNewWindow: true,
        }
      : null,
    {
      isNewWindow: true,
      label: globalFooterLinks.careers,
      url: 'https://jobs.hilton.com/us/en',
      onClick: handleClick,
    },
    {
      isNewWindow: true,
      label: globalFooterLinks.development,
      url: 'https://www.hilton.com/en/corporate/development/',
      onClick: handleClick,
    },
    {
      isNewWindow: true,
      label: globalFooterLinks.media,
      url: 'https://stories.hilton.com/',
      onClick: handleClick,
    },
    {
      label: globalFooterLinks.usageAgreement,
      url: `/${locale}/p/site-usage-agreement/`,
      isNewWindow: true,
    },
    {
      label: globalFooterLinks.partnerAccommodations,
      url: `/${locale}/p/partner-accommodations-terms/`,
      isNewWindow: true,
    },
    {
      label: globalFooterLinks.modernSlavery,
      url: 'https://ir.hilton.com/~/media/Files/H/Hilton-Worldwide-IR-V3/committee-composition/hilton-slavery-and-trafficking-statement-2019.pdf',
      onClick: handleClick,
      isNewWindow: true,
    },
    {
      label: globalFooterLinks.discountTermsAndConditions,
      url: `/${locale}/hilton-honors/honors-discount-terms/`,
      isNewWindow: true,
      onClick: handleClick,
    },
    {
      label: globalFooterLinks.termsAndConditions,
      url: `/${locale}/hilton-honors/terms/`,
      isNewWindow: true,
      onClick: handleClick,
    },
    {
      isNewWindow: true,
      label: globalFooterLinks.tourismMediator,
      url: 'https://www.hilton.com/en/p/tourism-and-travel/',
      onClick: handleClick,
    },
    {
      isNewWindow: true,
      label: globalFooterLinks.adChoices,
      url: 'http://optout.aboutads.info/?c=2&lang=EN#!%2F',
      onClick: handleClick,
    },
  ].filter(Boolean) as Link[];
