import * as React from 'react';
import cx from 'classnames';
import Icon from '@dx-ui/osc-icon';

export type ControlNextProps = {
  /** used for screen readers and other assistive devices */
  label: string;
  /** the size of the control */
  size?: React.ComponentProps<typeof Icon>['size'];
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * A simple button to be used any time you need an interaction to go forward. like for pagination.
 */
export const ControlNext = React.forwardRef<HTMLButtonElement, ControlNextProps>(
  ({ className, disabled, label, size = 'md', ...rest }, forwardedRef) => {
    return (
      <button
        ref={forwardedRef}
        type="button"
        className={cx('btn btn-primary-text btn-lg text-current rtl:-scale-x-100', className)}
        disabled={disabled}
        {...rest}
      >
        <span className="sr-only">{label}</span>
        <Icon name="arrowhead-right" size={size} />
      </button>
    );
  }
);

ControlNext.displayName = 'ControlNext';

export default ControlNext;
