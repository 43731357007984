import { Header } from '@dx-ui/osc-header';
import { useTranslation } from 'next-i18next';

import { useLanguage } from '../../hooks';
import { logError } from '@dx-ui/framework-logger';
import { generateError } from '@dx-ui/utilities-generate-error';

export const OscMinimalHeader = () => {
  const language = useLanguage();
  const [t] = useTranslation('header');

  return (
    <Header
      brand={{ url: `/${language}/hilton-honors/`, name: t('hiltonHonors'), code: 'HH' }}
      isFluid={true}
      user={undefined}
      userLinks={{
        accountLink: undefined,
        signInLink: undefined,
        signUpLink: undefined,
      }}
      data-testid="newosc"
      onSignOut={() =>
        Promise.resolve().catch((error) => {
          logError('MINIMAL_HEADER', generateError(error), 'error on logout.');
        })
      }
      onSignInAttempt={() =>
        Promise.resolve().catch((error) => {
          logError('MINIMAL_HEADER', generateError(error), 'error on login.');
        })
      }
    />
  );
};
