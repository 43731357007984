export const SEGMENTATION_COOKIE_NAME = 'TMS';
export const SEGMENTATION_COOKIE_NAME_DEV = 'TMS_DEV';

export const CONFIG_RULE_NAMES = {
  'member-home': 'config-rule-honors-text-headliner',
  activity: 'config-rule-honors-activity-text-headliner',
} as const;

export const DEFAULT_BANNER_SEGMENT = {
  'member-home': '21539313',
  activity: '19493122',
} as const;

export const CONFIG_RULE_PATH = {
  'member-home': 'member-home',
  activity: 'guest/activity',
  'go-hilton': 'go-hilton/home',
} as const;
