const publicLocales = './public/locales';
const appPublicLocales = './apps/dx-guests-ui/public/locales';

/**
 * @type {import('next-i18next').UserConfig}
 */
module.exports = {
  i18n: {
    defaultLocale: 'default',
    locales: [
      'ar',
      'bg',
      'cs',
      'de',
      'default',
      'en',
      'es',
      'fi',
      'fr',
      'id',
      'it',
      'ja',
      'ko',
      'nl',
      'pl',
      'pt',
      'ro',
      'ru',
      'sv',
      'th',
      'tr',
      'zh-hans',
      'zh-hant',
    ],
  },
  returnNull: false,
  lowerCaseLng: true,
  debug: false,
  defaultNS: 'translation',
  fallbackLng: 'en',
  localePath: process.env.APP_ENV === 'local' ? appPublicLocales : publicLocales,
};
