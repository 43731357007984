/* eslint-disable react/jsx-no-duplicate-props */
import type * as React from 'react';
import { PopupButton, PopupContent, usePopup } from '@dx-ui/osc-popup';
import cx from 'classnames';
import { sendInteractionReward } from '../header.utilities';

type TNavItemWithPopup = {
  buttonChildren: React.ReactNode;
  expandedButtonChildren?: React.ReactNode;
  onClick?: () => void;
  theme?: Exclude<CmsBrandComponentTheme, 'light'>;
  popupButtonRef?: React.RefObject<HTMLButtonElement>;
  experimentationConfiguration?: CmsExperimentationConfiguration;
};

const NavItemWithPopup: React.FC<React.PropsWithChildren<TNavItemWithPopup>> = ({
  children,
  buttonChildren,
  expandedButtonChildren,
  onClick,
  theme,
  popupButtonRef,
  experimentationConfiguration,
}) => {
  const { isOpen } = usePopup();

  const isDark = theme === 'dark';

  return (
    <>
      <li
        className={cx('group flex h-full', {
          'nav-list-item': !theme,
          'nav-list-item-dark': isDark,
          'nav-list-item-active': isOpen && !theme,
          'nav-list-item-active-dark': isOpen && isDark,
        })}
      >
        <PopupButton
          onClick={() => {
            sendInteractionReward();
            onClick?.();
          }}
          className="mx-1 mt-1 flex items-center px-2"
          ref={popupButtonRef}
          data-conductrics-goal={experimentationConfiguration?.goal}
          data-conductrics-value={experimentationConfiguration?.value}
        >
          {isOpen && expandedButtonChildren ? expandedButtonChildren : buttonChildren}
        </PopupButton>
      </li>
      <PopupContent
        className="motion-safe:animate-fadein !popup-margin !w-screen !p-0"
        popupStyles={{ left: 0 }}
      >
        {children}
      </PopupContent>
    </>
  );
};

export default NavItemWithPopup;
