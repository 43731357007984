import { sendRewardAsync } from '@dx-ui/framework-conductrics';
import { logError } from '@dx-ui/framework-logger';

import { Goals } from '../../types/Conductrics';

export const GoalByCardName = {
  'Hilton Honors': Goals.HonorsApplyNow,
  'Hilton Honors Surpass®': Goals.SurpassApplyNow,
  'Hilton Honors Aspire': Goals.AspireApplyNow,
  'Hilton Honors Business': Goals.BusinessApplyNow,
} as const;

export const sendConductricsReward = (goalID: ValuesOf<typeof Goals>) => {
  sendRewardAsync(goalID).catch((error) =>
    logError('sendRewardError', error, `Reward ID: ${goalID}`)
  );
};
