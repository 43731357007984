import { createContext, useEffect } from 'react';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useRouter } from 'next/router';

import { useLanguage } from '../../hooks/useLanguage';
import { getQueryParameters } from '../../utils/routeHelpers';
import { defaultPageData } from './digitalData';
import metrics from './metrics';

import type { GuestQuery } from '@dx-ui/framework-auth-provider';
import type { ExtendedAdobeTagManager } from './ExtendedAdobeTagManager';

type TrackingData = {
  trackingData: { pageData: typeof defaultPageData } & { pageView: string };
};

export const MetricsContext = createContext<{ metrics: ExtendedAdobeTagManager }>({
  metrics: metrics.api,
});

const goUserTypes = ['OAS', 'ODM', 'GHFT', 'GHFF', 'TMH', 'FF'];

const hgvUserTypes = ['HGD'];

const smbUserTypes = ['SMBO', 'SMBA', 'SMBC', 'SMBE', 'SMBP'];

const getGuestUserType = (guestInfo: GuestQuery['guest'], userTypeArray: string[]) => {
  return guestInfo?.hhonors?.packages?.find((pack) => userTypeArray.includes(pack.packageName));
};

const getSMBGuestUserType = (guestInfo: GuestQuery['guest'], userTypeArray: string[]) => {
  return guestInfo?.hhonors?.packages?.filter((pack) => userTypeArray.includes(pack.packageName));
};

export const MetricsProvider = ({
  children,
  trackingData = { pageData: defaultPageData },
}: {
  children?: React.ReactNode;
  trackingData: Partial<TrackingData['trackingData']>;
}) => {
  const language = useLanguage();
  const router = useRouter();
  const { isAuthenticated, guestInfo, isLoading } = useAuth();

  useEffect(() => {
    if (!isLoading) {
      const goGuestType = getGuestUserType(guestInfo, goUserTypes);
      const hgvMaxGuestType = getGuestUserType(guestInfo, hgvUserTypes);
      const smbGuestType = getSMBGuestUserType(guestInfo, smbUserTypes);
      const userType: Record<string, string> = {};

      if (goGuestType) {
        userType['goUserType'] = goGuestType.packageName;
      }
      if (hgvMaxGuestType) {
        userType['goUserType'] = hgvMaxGuestType.packageName;
      }
      if (smbGuestType && smbGuestType?.length) {
        userType['goUserType'] = smbGuestType.map((packages) => packages?.packageName).join(',');
      }

      // Track session
      metrics.api.setGuestPageData([
        {
          profileID: guestInfo?.hhonors?.hhonorsNumber || '',
          pointsBalance: guestInfo?.hhonors?.summary?.totalPoints || '',
          rewardsTier: guestInfo?.hhonors?.summary?.tierName || '',
          attributes: {
            loginStatus: isAuthenticated ? 'Logged-in' : 'Logged-out',
            ...userType,
          },
        },
      ]);

      // Track page load
      metrics.api.setDefaultPageData([
        {
          ...defaultPageData,
          ...trackingData.pageData,
          loginStatus: isAuthenticated ? 'Logged-in' : 'Logged-out',
          language,
        },
      ]);
    }
  }, [trackingData, isAuthenticated, guestInfo, language, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      metrics.api.trackPageView(trackingData.pageView);
    }
  }, [trackingData, isLoading]);

  useEffect(() => {
    const handleTrackedRouteChange = (url: string) => {
      const { eventAction, pageDetail } = getQueryParameters(url);
      if (eventAction || pageDetail) {
        if (eventAction) {
          metrics.api.setEventAction([{ eventAction }]);
          metrics.api.trackEvent(eventAction);
        }
        if (pageDetail) {
          metrics.api.setPageDetails([{ pageDetail }]);
          metrics.api.trackPageDetails();
        }
      }
    };

    router.events.on('routeChangeStart', handleTrackedRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleTrackedRouteChange);
    };
  }, [router]);

  return (
    <MetricsContext.Provider value={{ metrics: metrics.api }}>{children}</MetricsContext.Provider>
  );
};
