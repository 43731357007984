import Icon from '@dx-ui/osc-icon';
import { useIsClient } from 'usehooks-ts';
import { isKanji, useLocale, useDateFormat } from '@dx-ui/utilities-dates';
import cx from 'classnames';
import type * as React from 'react';

type DatePickerDatesButton = {
  children?: React.ReactNode;
  className?: HTMLDivElement['className'];
  day: Date;
  dayTextColorClassName?: string;
  language: string;
  isReducedSize?: boolean;
};

const DatePickerDatesButton: React.FC<React.PropsWithChildren<DatePickerDatesButton>> = ({
  className,
  children,
  day,
  dayTextColorClassName = 'text-primary',
  language,
  isReducedSize,
}) => {
  const locale = useLocale({ language });
  const kanji = isKanji(locale) as boolean;
  const options = kanji ? { weekday: 'long' as Intl.DateTimeFormatOptions['weekday'] } : {};
  const dateFormatParts = useDateFormat({ date: day, locale, options });
  const isClient = useIsClient();

  const reducedSubTextSize = {
    'text-xs': isReducedSize,
    'text-base': !isReducedSize,
  };

  if (!day) {
    return (
      <span className="flex w-36 items-center justify-center whitespace-nowrap sm:w-auto sm:ps-2 sm:first-of-type:ps-0">
        <Icon name="calendar" size="xl" variant="solid" className="text-primary" />
        {children ? (
          <span data-osc-product="date-picker-buttons" className="ms-2 text-sm leading-tight">
            {children}
          </span>
        ) : null}
      </span>
    );
  }

  if (!isClient) {
    return (
      <span className="flex w-36 items-center justify-center whitespace-nowrap sm:w-auto sm:ps-2 sm:first-of-type:ps-0">
        <span className={cx('bg-bg-alt w-20 rounded', kanji ? 'h-12' : 'h-11')} />
      </span>
    );
  }

  return kanji ? (
    <span
      aria-hidden
      className={cx(
        'flex h-12 items-center whitespace-nowrap sm:w-auto sm:ps-2 sm:first-of-type:ps-0',
        className
      )}
    >
      <span className="row-span-1 row-start-2">
        <span className={cx('block uppercase tabular-nums leading-none', reducedSubTextSize)}>
          {dateFormatParts.month.numeric}
          {dateFormatParts.month.text}
        </span>
      </span>
      <span className="row-span-4 row-start-1">
        <span
          className={cx('pe-1 font-bold tabular-nums leading-none', dayTextColorClassName, {
            'text-3xl md:text-2xl lg:text-3xl': isReducedSize,
            'text-4xl md:text-3xl lg:text-4xl': !isReducedSize,
          })}
        >
          {dateFormatParts.day.numeric}
          <span className="uppercase tabular-nums">{dateFormatParts.day.text}</span>
        </span>
        <span className={cx('block uppercase tabular-nums leading-none', reducedSubTextSize)}>
          {dateFormatParts.weekday}
        </span>
      </span>
    </span>
  ) : (
    <span
      aria-hidden
      className={cx(
        'flex w-32 items-end justify-center sm:w-auto sm:ps-2 sm:first-of-type:ps-0',
        className,
        {
          'h-8': isReducedSize,
          'h-11': !isReducedSize,
        }
      )}
    >
      <span
        className={cx(
          'brand-ey:font-normal brand-lx:font-serif brand-lx:text-brand brand-ou:font-headline brand-ou:font-normal pe-1 font-bold tabular-nums leading-[0.9]',
          dayTextColorClassName,
          {
            'text-4xl': isReducedSize,
            'text-5xl': !isReducedSize,
          }
        )}
      >
        {dateFormatParts.day.numeric}
      </span>
      <span className="flex flex-col">
        <span
          className={cx(
            'brand-ey:font-normal brand-lx:font-semibold brand-ou:text-primary block font-bold uppercase tabular-nums leading-none',
            reducedSubTextSize
          )}
        >
          {dateFormatParts.month.text}
        </span>
        <span
          className={cx('brand-ou:text-primary block uppercase tabular-nums', reducedSubTextSize)}
        >
          {dateFormatParts.weekday}
        </span>
      </span>
    </span>
  );
};

export { DatePickerDatesButton };
export default DatePickerDatesButton;
