import cx from 'classnames';
import * as React from 'react';
import { get, useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { FormError } from './form.error';
import { FormHelpMessage } from './form.help-message';
import { FormLabel } from './form.label';
import type { FormInputBase } from './form.types';

/**
 * This component is to be used in place of all `<textarea>` elements
 */
const FormTextarea: React.FC<
  React.PropsWithChildren<FormInputBase<React.TextareaHTMLAttributes<HTMLTextAreaElement>>>
> = ({
  label,
  name,
  optional,
  required,
  registerOptions,
  labelClassName,
  helpMessage,
  loading,
  className,
  containerClassName,
  maxLength,
  ...rest
}) => {
  const id = React.useId();
  const errorId = `input-error-${id}`;
  const helperId = `input-helper-${id}`;
  const [t] = useTranslation('osc-form');
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const inputName = name;
  const inputValue = watch(inputName);
  const fieldError = get(errors, inputName);
  const hasError = !!fieldError;

  const length = inputValue?.length || 0;
  const percent = (maxLength && length / maxLength) || 0;
  const remaining = maxLength && maxLength - length;

  return (
    <div className={containerClassName}>
      <FormLabel
        label={label}
        required={required}
        optional={optional}
        hasError={hasError}
        className={cx('self-start', labelClassName)}
      >
        <div className="relative">
          <textarea
            {...rest}
            maxLength={maxLength}
            required={required}
            className={cx('form-textarea w-full', className, {
              'form-error': hasError,
            })}
            aria-invalid={hasError}
            aria-describedby={`${errorId} ${helperId}`}
            {...register(inputName, {
              required: required ? t('requiredError') : false,
              ...registerOptions,
            })}
          />
          {!!maxLength && (
            <p
              aria-live="polite"
              className={cx(
                'bg-bg/70 pointer-events-none absolute bottom-2 select-none px-1 pt-1 text-xs font-normal ltr:right-0.5 rtl:left-0.5',
                {
                  'text-text-alt': percent < 0.8,
                  'text-warn': percent >= 0.8 && percent < 1,
                  'text-danger': percent >= 1,
                }
              )}
              data-testid="form-textarea-counter"
            >
              <span aria-hidden>
                {length}/{maxLength}
              </span>
              <span className="sr-only">{t('remaining', { count: remaining })}</span>
            </p>
          )}
        </div>
      </FormLabel>
      <FormHelpMessage id={helperId} message={helpMessage} loading={loading} />
      <FormError id={errorId} error={fieldError} />
    </div>
  );
};

export { FormTextarea };
export default FormTextarea;
