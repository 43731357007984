import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Footer as OSCFooter } from '@dx-ui/osc-footer';
import { Speedbump, useSpeedbump } from '@dx-ui/osc-speedbump';

import { REACT_QUERY_CONFIG } from '../../config/react-query';
import { GlobalFooterLinks } from './GlobalFooterLinks';

const GlobalFooter: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { locale = 'en' } = useRouter();
  const [t] = useTranslation('footer');
  const {
    onShow: handleShowSpeedbump,
    onHide,
    isVisible,
    onContinue,
  } = useSpeedbump(locale, REACT_QUERY_CONFIG.appName);

  return (
    <div className="border-border mb-20 border-t border-solid lg:mb-0">
      <Speedbump isShowing={isVisible} onClose={onHide} onContinue={onContinue} />
      <OSCFooter
        className="border-t-0"
        contactPhoneLink={{ url: 'tel:+1-800-446-6677', label: '+1-800-4honors' }}
        customerSupportLink={{
          url: 'https://help.hilton.com/s/',
          target: '_blank',
          rel: 'noopener noreferrer',
          onClick: (event) => handleShowSpeedbump(event),
        }}
        links={GlobalFooterLinks(
          t('globalFooterLinks', { returnObjects: true }),
          locale,
          handleShowSpeedbump
        )}
      />
    </div>
  );
};

export default GlobalFooter;
