import { env } from '@dx-ui/framework-env';
import { useState } from 'react';
import Cookies from 'universal-cookie';

const isBrowser: boolean = typeof window !== 'undefined';

function getCookie(name: string, cookies?: string) {
  const cookie = new Cookies(cookies);
  return cookie.get(name) || '';
}

// cookie name for the real Adobe Audience Manager cookie
export const TMS_LIVE_COOKIE_NAME = 'TMS';
// in lower environments a dev cookie can be used since Adobe launch script overwrites any changes on page load, which makes testing challenging
export const TMS_DEV_COOKIE_NAME = 'TMS_DEV';

export const hasDevSegmentCookie = (): boolean => !!getCookie(TMS_DEV_COOKIE_NAME);

// for lower environments check if we have a dev cookie & use if it exists
const getSegmentCookie = () => {
  let cookie = '';
  if (isBrowser) {
    if (env('APP_ENV') !== 'prd') {
      cookie = getCookie(TMS_DEV_COOKIE_NAME);
    }
    if (!cookie) {
      cookie = getCookie(TMS_LIVE_COOKIE_NAME);
    }
  }
  return cookie;
};

export const getUserSegmentsFromCookie = (cookie: string) =>
  cookie
    .split(',')
    .filter((entry) => (entry.toLowerCase() || '').startsWith('web'))
    .map((entry) => entry.split('=')[1] || '');

export const useUserSegments = () => {
  const [val] = useState(() => {
    const cookie = getSegmentCookie();
    return getUserSegmentsFromCookie(cookie);
  });
  return val;
};
