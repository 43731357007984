import { RuleEngine } from '@dx-ui/utilities-url-config';

import {
  serverSideFeatureConfigsQuery,
  serverSideFeatureTogglesQuery,
} from '../queries/generated/react-query';

import type { Rule } from '@dx-ui/utilities-url-config';
import type { QueryClient } from '@tanstack/react-query';
import type { FeatureConfigsQuery, FeatureTogglesQuery } from '../types/generated/types';
import { logError, logWarning } from '@dx-ui/framework-logger';
import { generateError } from '@dx-ui/utilities-generate-error';

export const getRouteProtectedFeatureToggles = async (
  client: QueryClient,
  routeFlag: string,
  additionalFlags: string[] = []
): Promise<{ featureToggles: FeatureTogglesQuery['featureToggles']; routeEnabled: boolean }> => {
  return await serverSideFeatureTogglesQuery(client, {
    flags: [routeFlag, ...additionalFlags],
  })
    .then((toggles) => {
      const routeEnabled = isFeatureEnabled(toggles?.featureToggles, routeFlag);
      if (!routeEnabled) {
        logWarning(
          'FEATURE_TOGGLES',
          `route disabled for ${routeFlag}`,
          `attempted access to disabled route | flags: ${routeFlag} | additionalFlags: ${additionalFlags}`
        );
      }
      return {
        routeEnabled,
        featureToggles: toggles?.featureToggles,
      };
    })
    .catch((error) => {
      logError(
        'FEATURE_TOGGLES',
        generateError(error),
        `Error on ${serverSideFeatureTogglesQuery.name} | flags: ${routeFlag} | additionalFlags: ${additionalFlags}`
      );
      return { featureToggles: [], routeEnabled: false };
    });
};

export const useServerSideFeatureToggles = async (client: QueryClient, flags: string[] = []) => {
  return await serverSideFeatureTogglesQuery(client, { flags })
    .then((featureTogglesQuery) => {
      return featureTogglesQuery?.featureToggles;
    })
    .catch((error) => {
      logError(
        'FEATURE_TOGGLES',
        generateError(error),
        `Error on ${useServerSideFeatureToggles.name} | flags: ${flags}`
      );
    });
};

export const getSupportedLanguages = async ({
  client,
  locales,
  path,
}: {
  client: QueryClient;
  locales: string[] | undefined;
  path: string;
}) => {
  const configRules = await serverSideFeatureConfigsQuery(client, {
    names: ['rule-ui-translate-urls'],
  }).catch((e) => {
    logError('Error on serverSideFeatureConfigsQuery at utils/featureToggles: ', e);
    return {
      featureConfigs: [],
    } as FeatureConfigsQuery;
  });

  const ruleEngine = new RuleEngine(configRules?.featureConfigs?.[0] as Rule);
  return {
    supportedLanguages: locales?.filter((language) => ruleEngine?.run?.({ url: path, language })),
    configRules,
  };
};

export const isFeatureEnabled = (
  toggles: FeatureTogglesQuery['featureToggles'],
  toggleName: string
) => {
  try {
    return toggles.find(({ name }) => toggleName === name)?.enabled || false;
  } catch (error) {
    logError('FEATURE_TOGGLES', generateError(error), `toggles.find() | toggleName: ${toggleName}`);
    return false;
  }
};
